import { mapActions, mapGetters } from 'vuex'
import CInputDateRange from '@/components/CInputDateRange'
import CSelectSearch from '@/components/CSelectSearch'
import table from '@/mixin/table'
import GCFilter from '@/components/GCFilter/index'
import breadcrumbsMixin from '../breadcrumbsMixin'
import { notifyByPlan } from '@/services/Notify/Toasts'
import Gate from '@/services/Gate/Gate'
import onTimeoutSearch from '@/mixin/searchTimeout'

export default {
  name: 'ProjectsList',
  mixins: [table, breadcrumbsMixin, onTimeoutSearch],
  components: { CInputDateRange, CSelectSearch, GCFilter },
  data() {
    return {
      fields: [
        {
          key: 'project_name',
          label: 'Project Name',
          _style: 'width: 400px',
          link: {
            name: 'ProjectDashboard',
            params: { id: 'id' },
            iconNewTab: true,
          },
        },
        {
          key: 'company_name',
          label: 'Company Name',
          _style: '',
          link: { name: 'CompanyInfo', params: { id: 'company_id' } },
        },
        { key: 'internal_number', label: 'Project Number', _style: '' },
        { key: 'owner_name', label: 'Owner Name', _style: '' },
        { key: 'stage', label: 'Stage', _style: '' },
        { key: 'estimated_start_date', label: 'Start Date', _style: '' },
        { key: 'estimated_end_date', label: 'End Date', _style: '' },
        {
          key: 'manager_full_name',
          label: 'Project Manager',
          _style: '',
          link: {
            name: 'CompanyUser',
            params: { user_id: 'manager_id', id: 'company_id' },
          },
        },
        { key: 'manager_phone', label: 'Manager Phone', _style: '' },
        {
          key: 'manager_email',
          label: 'Manager Email',
          _style: 'width: 100px',
          email: true,
        },
      ],
      tempFilter: {},
    }
  },
  computed: {
    ...mapGetters(['projects']),
  },
  created() {
    this.$nextTick(() => {
      this.tempFilter.search = this.$route.query.search || ''
    })
  },
  methods: {
    ...mapActions(['fetchProjects']),
    async handleCreateProject() {
      ;(await Gate.can('create', 'project'))
        ? this.$router.push({ name: 'ProjectNew' })
        : notifyByPlan()
    },
    openInNewTab(id) {
      let routeData = this.$router.resolve({
        name: 'ProjectDashboard',
        params: { id: id },
      })
      window.open(routeData.href, '_blank')
    },
    fetchDataProjects(filter = {}) {
      this.tempFilter = this.$deepClone(filter)
      this.fetchData()
    },
    fetchData() {
      this.loadingTable = true
      this.fetchProjects({
        params: {
          ...this.tempFilter,
          per_page: this.perPage,
          page: this.activePage,
          sort_field: this.sort && this.sort.column,
          sort_direction: this.sort && this.sort.state,
        },
      })
        .then((res) => {
          this.pages = res.meta.last_page
          if (this.activePage > this.pages) {
            this.activePage = this.activePage - 1
          }
        })
        .finally(() => (this.loadingTable = false))
    },
  },
}
