<template>
  <CCard class="projects-list">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem active>Projects</CBreadcrumbItem>
    </teleport>

    <teleport v-if="mountedComponent" to="#projects-header">
      <h1 class="projects-list__heading">Projects</h1>
    </teleport>

    <CCardBody>
      <CRow class="w-100 mx-0 mb-3">
        <CCol class="GCFilterMaxWidth GCFilter col-12">
          <GCFilter
            class=""
            component="Projects"
            @search="(val) => onTimeoutSearch(() => fetchDataProjects(val))"
          />
        </CCol>
      </CRow>
      <SmartTable
        :items="projects"
        :items-per-page="parseInt(perPage)"
        :columns="fields"
        :sorter-value="sort"
        :selected-items="selected"
        :loading="loadingTable"
        :active-page="activePage"
        :per-page="perPage"
        :clear-text="search?.length ? 'No search results found' : 'No Data yet'"
        :pages="pages"
        @sorter-change="onSort"
        @selected-items-change="(s) => (selected = s)"
        @update-active-page="(value) => (activePage = value)"
        @update-per-page="(value) => (perPage = value)"
      >
        <template #clear_text>
          <div
            class="table__clear d-flex align-items-center justify-content-center mb-2 w-100"
          >
            <template v-if="search.length">
              <div class="table__clear-no-results">
                No search results found
              </div></template
            >
            <template v-else>
              <CIcon
                class="table__clear__icon"
                size="6xl"
                name="cilClipboard"
              />
              <div class="table__clear__title">No projects yet</div>
              <div class="table__clear__title-sub">
                Add projects you have created will be displayed here.
              </div>
              <CButton
                color="primary"
                class="table__clear__btn"
                @click="handleCreateProject"
              >
                Create a project
              </CButton>
            </template>
          </div>
        </template>
      </SmartTable>
    </CCardBody>
  </CCard>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
<style lang="scss" scope>
.projects-list {
  &__heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #1c262f;
  }
}
.table__clear {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 30px 60px;
  background: #f5f7f9;

  &-no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 18px;
    min-height: 146px;
  }

  &__icon {
    color: #b1b7c1;
    margin-bottom: 20px;
  }

  &__title {
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 18px;
    color: #303c54;

    &-sub {
      margin-bottom: 35px;
      font-weight: 400;
      font-size: 14px;
      color: #465967;
    }
  }
}

.card-body {
  border-radius: 0.25rem !important;
}

.card .card-header {
  border-bottom: none !important;
}

.GCFilter {
  padding-left: 0.25rem !important;
}
</style>
